@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
	--primary-dark-color: #000000;
	--primary-dark-alt-color: #4e4e4e;
	--secondary-dark-color: #111111;
	--primary-contrast-color: #6768cc;
	--primary-gray-color: #bcbcbc;
	--primary-light-color: #eeeeee;
	--secondary-light-color: #f2f2f2;
	--white-color: #ffffff;
	--palette-blue: #5c97bf;
	--palette-teal: #00a4a6;
	--palette-red: #f64747;
	--palette-green: #00aa55;
	--palette-gold: #ffc370;
	--palette-orange: #e65722;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
	letter-spacing: 0.03em;
	line-height: 25px;
}

html {
	background-color: #eeeeee;
	background-color: var(--primary-light-color);
}

body {
	margin: 0;
	/* line-height: 25px; */
}

a {
	text-decoration: none;
}

.app-header a.nav-item__active > span {
	border-bottom-color: rgba(108, 77, 246, 1);
	border-bottom-style: solid;
	border-bottom-width: 3px;
	border-radius: 1px;
}

.sidebar-container {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.sidebar-container a.nav-item__active > span {
	/* border-right-color: var(--white-color); */
	/* border-right-style: solid; */
	/* border-right-width: 3px; */
	/* border-radius: 1px; */
	position: relative;
}
.sidebar-container a.nav-item__active > span::after {
	top: calc(50% - 10px);
	right: -16px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #eeeeee;
	border-top-color: var(--primary-light-color);
	border-width: 10px;
	margin-right: 0px;
	margin-left: 8px;
	transform: rotate(90deg);
}

@media (max-width: 800px) {
	.main-container .splash {
		flex-direction: column-reverse;
	}
	.splash .splash-box--left,
	.splash .splash-box--right {
		width: 100%;
	}
}

/* LIBRARY */
.btn--primary:hover {
	background-color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color;
}

.btn--standard:hover {
	color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color;
}

.divider {
	border-top: 1px solid var(--primary-gray-color);
	margin: 8px 0;
}

/* LIBRARY */

.card {
	/* color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color; */
	box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
	height: 326px;
	width: 449px;
	left: 252px;
	top: 192px;
	border-radius: 5px;
	background-color: var(--white-color);
}

@media (max-width: 1024px) {
	.legend-table--table-blocks--table-blocks-block {
		width: 48% !important;
		margin: 1% !important;
	}
}
@media (max-width: 750px) {
	.legend-table--table-blocks--table-blocks-block {
		width: 98% !important;
		margin: 1% !important;
		max-width: 100% !important;
	}
}

.field-input-row > input.field-input {
	box-shadow: none !important;
}



