@media (max-width: 1024px) {
	.legend-table--table-blocks--table-blocks-block {
		width: 48% !important;
		margin: 1% !important;
	}
}
@media (max-width: 750px) {
	.legend-table--table-blocks--table-blocks-block {
		width: 98% !important;
		margin: 1% !important;
		max-width: 100% !important;
	}
}
