/* LIBRARY */
.btn--primary:hover {
	background-color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color;
}

.btn--standard:hover {
	color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color;
}
