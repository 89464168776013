/* LIBRARY */

.card {
	/* color: var(--primary-dark-alt-color) !important;
	border-color: var(--primary-dark-alt-color) !important;
	transition: ease-in-out border-color; */
	box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
	height: 326px;
	width: 449px;
	left: 252px;
	top: 192px;
	border-radius: 5px;
	background-color: var(--white-color);
}
